import { contactSubmit } from './contact.js';
import { navToggle } from './header.js';
import { footerFormSubmit } from './footer_form.js';
import { heroCarousel, aboutACarousel, aboutBCarousel } from './home.js';
import { facilityCarousel, filters, viewMoreUnits } from './facility.js';
import { features, featuresToggle } from './features.js';
import { reviewsCaro, reviewsMobileCaro } from './reviews.js';
import { faqsToggle, helpCta } from './help.js';
import { locationMap } from './map.js';

navToggle.init();

if (document.getElementById('footer_form')) {
	footerFormSubmit();
}

if(document.querySelector('#home_hero_caro')) {
	heroCarousel.init();
}

if(document.querySelector('#facility_caro')){
	facilityCarousel.init();
}

if(document.getElementById('facility_filters')){
	filters.init();
}

if(document.getElementById('view_more_units')){
	viewMoreUnits.init();
}

if(document.querySelector('#features')) {
	features.init();
	featuresToggle.init();
}

if(document.querySelector('#home_cta')) {
	aboutACarousel.init();
	aboutBCarousel.init();
}

if(document.querySelector('#reviews_caro')){
	reviewsCaro.init();
}

if(document.querySelector('#reviews_caro_mobile')){
	reviewsMobileCaro.init();
}

if (document.getElementById('contact_form')) {
	contactSubmit();
}

if(document.getElementById('faqs_content')) {
	faqsToggle.init();
}

if(document.getElementById('help_cta')) {
	helpCta.init();
}

if(document.getElementById('contact_map')){
	locationMap.init();
}