import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const facilityCarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('facility_caro')) {
			let interval = 5000;
			if(window.facility_slide_images.length === 1){
				interval = false;
			}
			const options = {
				element: document.getElementById('facility_caro'),
				images: window.facility_slide_images,
				imagesAsBackgrounds: true,
				intervalTiming: interval,
				showDots: true,
				useChevrons: true
			};
			window.facilityCarousel = AutomatitCarousel(options);
		}
	};

	return my;
})();

export const filters = (function(){
	let my = {};

	function _filterUnits(e){
		var unitShown = 0;
		const noUnits = document.querySelector('#no_units');
		document.querySelector("#facility_filters .active").classList.remove('active');
		e.currentTarget.classList.add('active');

		let size = e.currentTarget.dataset.size;
		[].forEach.call(document.querySelectorAll('#facility_units .unit_card'), (row) => {
			row.style.display = 'none';
			if(size === "all" || row.dataset.props.includes(size)){
				row.style.display = 'flex';
				unitShown++;

			}
			if(unitShown === 0){
				noUnits.style.display = 'block';
			}else {
				noUnits.style.display = 'none';
			}
		});
	}

	function _assignListeners(){
		[].forEach.call(document.querySelectorAll('#facility_filters .rates_filter'), (filter) => {
			filter.addEventListener('click', _filterUnits);
		});
	}

	my.init = function(){
		if(document.getElementById('facility_filters')){
			_assignListeners();
		}
	};

	return my;
})();

export const viewMoreUnits = (function(){

	let my = {};

	my.init = function () {
		const units = document.querySelectorAll('#facility_units .unit_card');

		let unitMinimum = 7;
		if(screen.width < 700){
			unitMinimum = 5;
		}

		for (let index = 0; index < units.length; index++) {
			let unit = units[index];
			if(index > unitMinimum) {
				unit.classList.add('mobile_hide');
			}
		}
		
		let hiddenUnits = document.querySelectorAll('.mobile_hide');

		const showUnits = () => {

			hiddenUnits.forEach(hiddenUnit => {
				hiddenUnit.classList.remove('mobile_hide');
			});
		};

		document.getElementById('view_more_units').addEventListener('click', e => {
			let viewMoreBtn = e.currentTarget;
			viewMoreBtn.style.display = 'none';
			showUnits();
		});

		document.querySelector('#facility_filters .facility_filters_buttons').addEventListener('click', e => {
			if(e.currentTarget.classList.contains('facility_filters_buttons')) {
				document.getElementById('view_more_units').style.display = 'none';
			}
		});
	};

	return my;
})();