export const faqsToggle = (function(){

	let my = {};

	my.init = function () {
		const faqItemWrappers = document.querySelectorAll('.faq_item_wrapper');

		const removeClass = (items, className) => {
			items.forEach(item => {
				item.classList.remove(className);
			});
		};

		faqItemWrappers.forEach(faqItemWrapper => {
			faqItemWrapper.addEventListener('click', e => {
				let clickedItem = e.currentTarget;
				if(clickedItem.classList.contains('active')){
					clickedItem.classList.remove('active');
					removeClass(faqItemWrappers, 'active');
				} else {
					removeClass(faqItemWrappers, 'active');
					clickedItem.classList.add('active');
				}
			});
		});
	};

	return my;
})();

export const helpCta = (function(){

	let my = {};

	my.init = function () {
		const ctaItems = document.querySelectorAll('.help_cta_item');

		ctaItems.forEach(ctaItem => {
			ctaItem.addEventListener('click', e => {
				let clickedItem = e.currentTarget.getAttribute('data-cta-link');
				window.open(clickedItem, "_self");
			});
		});
	};

	return my;
})();