export const features = (function(){

	let my = {};

	my.init = function () {
		const featureItems = document.querySelectorAll('#features .feature');

		featureItems.forEach(featureItem => {
			featureItem.addEventListener('mouseover', e => {
				let featureVideo = e.currentTarget.querySelector('.feat_video');
				console.log(featureVideo);
				featureVideo.play();
			});

			featureItem.addEventListener('mouseleave', e => {
				let featureVideo = e.currentTarget.querySelector('.feat_video');
				featureVideo.pause();
			});
		});
	};

	return my;
})();

export const featuresToggle = (function(){

	let my = {};

	my.init = function () {
		const featureItems = document.querySelectorAll('#features .feature');

		for (let index = 0; index < featureItems.length; index++) {
			let element = featureItems[index];
			if(index > 2) {
				element.classList.add('mobile_hide');
			}
		}
		
		let hiddenFeatures = document.querySelectorAll('.mobile_hide');

		const showFeatures = () => {

			hiddenFeatures.forEach(hiddenFeature => {
				hiddenFeature.classList.remove('mobile_hide');
			});
		};

		document.getElementById('view_more_features').addEventListener('click', e => {
			let viewMoreBtn = e.currentTarget;
			viewMoreBtn.style.display = 'none';
			showFeatures();
		});
	};

	return my;
})();