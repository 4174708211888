export const navToggle = (function(){
	let my = {};

	function _mobileToggle() {
		let toggles = document.querySelectorAll('.menu_toggle');
		toggles.forEach(toggle => {
			toggle.addEventListener('click', function(e){
				let clickedButton = e.currentTarget;
				let nav = document.getElementById('header_nav');
    
				clickedButton.toggleAttribute('open');
				nav.toggleAttribute('open');
			});
		});
	}

	function _navDrop() {
		document.querySelectorAll('.nav_item').forEach(item => {
			item.addEventListener('click', () => {
				if (item.querySelector('.has_drop')) {
					item.classList.toggle('active');
				}
			});
		});
	}

	my.init = function(){
		_mobileToggle();
		_navDrop();
	};

	return my;
})();