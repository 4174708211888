import {
	AutomatitCarousel
} from './automatit_carousel.m';

export const heroCarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('home_hero_caro')) {
			const options = {
				element: document.getElementById('home_hero_caro'),
				images: window.home_slide_images,
				imagesAsBackgrounds: true,
				showDots: false,
				useChevrons: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();

export const aboutACarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('home_cta_carousel_a')) {
			const options = {
				element: document.getElementById('home_cta_carousel_a'),
				images: window.home_about_a_slide_images,
				intervalTiming: false,
				imagesAsBackgrounds: true,
				showDots: true,
				useChevrons: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();

export const aboutBCarousel = (function(){

	let my = {};

	my.init = function () {
		if (document.getElementById('home_cta_carousel_b')) {
			const options = {
				element: document.getElementById('home_cta_carousel_b'),
				images: window.home_about_b_slide_images,
				intervalTiming: false,
				imagesAsBackgrounds: true,
				showDots: true,
				useChevrons: false
			};
			AutomatitCarousel(options);
		}
	};

	return my;
})();